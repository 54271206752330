*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
    background: lightgray;

}
a{
    color: unset;
    text-decoration: none;
}
/*
.gradient__bg{
       
}
*/
.gradient__text{
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding{
    padding: 4em 6em;
}
.section__margin{
    padding: 4em 6em;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @media screen and (max-width: 700px){
    .section__padding{
        padding: 4em;
    }
    .section__margin{
        padding: 4em;
    }
  }
  
  @media screen and (max-width: 550px){
    .section__padding{
        padding: 4em 2em;
    }
    .section__margin{
        padding: 4em 2em;
    }
  }
  