* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
.introduction_name {
  text-align: center;
  margin-top: 120px;
  background: lightblue;
  font-size: 42px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.introduction_desc {
  font-size: 16px;
  text-align: center;
  background: lightblue;
}
.introduction_text {
  text-align: center;
  background: lightblue;
  height: 150px;
  padding-left: 3px;
}

.projects {
  text-align: center;
  margin-top: 20px;
  background: lightblue;
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.projects_list {
  background: lightblue;
}

ul.projectUL {
  margin-left: 40%;

  background: lightblue;
  height: 150px;
}
.main {
  height: 55.5vh;
}
