.gamedevmain {
  height: 100%;
}
.gamedev-intro {
  text-align: center;
}
.gd_sections {
  display: flex;
}
.projectboxes_gd1 {
  width: 33.33%;
  background: green;
}
.projectboxes_gd2 {
  width: 33.33%;
  background: red;
}
.projectboxes_gd3 {
  width: 33.33%;
  background: blue;
}
.box_gd {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 250px;
  height: 250px;
  background-color: white;
  margin: 30px;
  margin-left: 35px;
}
.box_gd:hover {
  transform: scale(1.1);
}
set2 {
  height: 50px;
}
.gamedev_categories {
  background: lightcoral;
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.gamedev_categories p {
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.gamedev_category_spec1 {
  padding-left: 12.5%;
  
}
.gamedev_category_spec3, .gamedev_category_spec2{
  padding-left: 26.5%;
  
}
