.contact_body{
    min-height: 68.5vh;
    width: 100%;
}
.contact_img1{
    height: 75px;
    width: 300px;
}
.network1_img{
    height: 400px;
    width: 650px;
}
.linkedIn_img
{
    height: 50px;
    width: 60px;
}
.contact_Title{
    font-size: 42px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
