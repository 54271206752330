
.nav__navbar{
    background:-moz-radial-gradient(ellipse at 0% 0%, rgba(2, 64, 200, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background:-webkit-radial-gradient(ellipse at 0% 0%, rgba(2, 64, 200, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background:-o-radial-gradient(ellipse at 0% 0%, rgba(2, 64, 200, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background:-ms-radial-gradient(ellipse at 0% 0%, rgba(2, 64, 200, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background:radial-gradient(ellipse at 0% 0%, rgba(2, 64, 200, 1) 0%, rgba(0, 0, 0, 1) 100%);

    list-style-type: none;
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    font-family: Georgia, 'Times New Roman', Times, serif;

    width: 100%;    
    height: 80px;
}
.nav__navbar a, #Navlink{
    float: left;
    padding: 10px 16px; 
    margin: 1rem 1rem;
    text-align: left;
}

.Project-Dropdown{
    float: left;
    color: white;
    overflow: hidden;
}

.Project-Content{
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    z-index: 1;
    min-width: 280px;
    margin-top: 80px;   
    margin-left: 1px;
}

.Project-Content a, #Navlink{
    text-decoration: none;
    display: block;
    text-align: left;
    float: none;
    color: black;
    
}
.nav__navbar a:hover, .Project-Dropdown:hover .projecthover{
    color: rgb(145, 145, 145);
    text-decoration: underline;
  }

.Project-Content a:hover{
    color: black;
    border:3px solid #b6b6b6;
    text-decoration: underline;
}
.Project-Dropdown:hover .Project-Content {
    display: block;
  }