.footerdiv{
    background: #0e0d54;
    height: auto;  
    margin-top: auto;
    width: 100%;
}
.footer-mandiv
{
    width: 100%;
    height:1px;
}
.footer_contact{
    margin-left: 20px;
}
.footer_email{
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
}
.email_button{
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    width: 10%;
}
.message{
    margin-top: 5px;
    width: 300px;
    height: 80px;
}
.footer_extra_text{
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}